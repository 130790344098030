export default {
  'system-error': 'Произошла системная ошибка',
  'logout': 'Вы вышли из системы',
  'login' : 'Для начала войдите в систему',
  'access-denied': 'Не достаточно прав доступа',

  'auth/registration-success': 'Спасибо за регистрацию. Следуйте инструкциям, отправленым на указанную почту',
  'auth/user-not-found': 'Пользователя с таким именем и паролем не существует или заблокирован',
  'auth/username-is-not-valid': 'Некорректный логин',
  'auth/lastname-is-not-valid': 'Некорректная фамилия',
  'auth/firstname-is-not-valid': 'Некорректное имя',
  'auth/middlename-is-not-valid': 'Некорректное отчество',
  'auth/username-already-in-use': 'Логин уже занят',
  'auth/phone-already-in-use': 'Телефон уже занят',
  'auth/email-already-in-use': 'Email уже занят',
  //'auth/department-already-in-use': 'Отделу уже назначен руководитель',
  'auth/token-not-found': 'Не указан токен',
  'auth/token-incorrect': 'Не верный токен',
  'auth/request-password-reset-success': 'Проверьте вашу почту для дальнейших инструкций',
  'auth/request-password-reset-error': 'Ошибка сброса пароля',
  'auth/password-reset-success': 'Пароль изменен',
  'auth/password-reset-error': 'Ошибка смены пароля',

  'role/role-already-in-use': 'Роль уже существует',
  'role/role-has-users': 'У роли есть пользователи',

  'row/empty-start-end-date': 'Не указан период',
  'row/not-found': 'Запись не найдена',
  'row/create-success': 'Запись добавлена',
  'row/create-error': 'Ошибка добавления записи',
  'row/update-success': 'Запись обновлена',
  'row/update-error': 'Ошибка обновления записи',
  'row/delete-success': 'Запись удалена',
  'row/delete-error': 'Ошибка удаления записи',
  'row/delete-relationship-error': 'Нельзя удалить запись, т.к. она связана с данными в других таблицах',
  'row/clone-success': 'Запись клонирована',
  'row/clone-error': 'Ошибка клонирования записи',
  'row/export-error': 'Ошибка экспорта записей',
  'row/import-success': 'Записи импортированы',
  'row/import-error': 'Ошибка импорта записей',
  'row/take-success': 'Запись взята в работу',
  'row/take-error': 'Ошибка взятия записи в работу',
  'row/take-already': 'Запись уже взята в работу',

  'row/lead-volume-null': 'Объем лидов исчерпан',

  'file/invalid-format': 'Не верный формат файла',

  'link-copied': 'Ссылка скопирована в буфер',
  'comment-copied': 'Комментарий скопирован в буфер',
  'password-copied': 'Пароль скопирован в буфер',

  'row/callback-lead-builders-update-success': 'Застройщики обновлены',
  'row/auto-callback-lead-projects-update-success': 'Проекты обновлены',

  'shop/buy-success': 'Товар куплен',
  'shop/no-balance': 'Не достаточно средств на балансе',

  'task/at-work': 'Задача взята в работу',
  'task/at-work-error': 'Ошибка взятия задачи в работу',
  'task/finished': 'Задача завершена',
  'task/finished-error': 'Ошибка завершения задачи',
  'task/saved': 'Задача сохранена',
  'task/saved-error': 'Ошибка сохранения задачи',

  'Setting update success': 'Настройки сохранены',
  'Setting update error': 'Ошибка сохранения настроек',

  valid: {
    required: 'Обязательное поле',
    username: 'От 2 символов, только латиница, цифры и -_',
    name: 'От 2 символов, только кириллица',
    phone: 'Неверный телефон',
  },
}
